import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Typography,
  Box,
  TablePagination,
  Chip,
  CircularProgress,
  Alert
} from '@mui/material';
import { API_BASE_URL } from '../config';

const BidsManagement = () => {
  const [bids, setBids] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pagination, setPagination] = useState({
    totalItems: 0,
    currentPage: 1,
    totalPages: 0,
    itemsPerPage: 10
  });

  const fetchBids = async (pageNum = 1, limit = rowsPerPage) => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(
        `${API_BASE_URL}/api/admin/bids?page=${pageNum}&limit=${limit}`,
        {
          headers: {
            'Authorization': `Bearer ${sessionStorage.getItem('adminToken')}`
          }
        }
      );
      
      if (!response.ok) {
        throw new Error('Failed to fetch bids');
      }
      
      const data = await response.json();
      setBids(data.bids);
      setPagination(data.pagination);
    } catch (error) {
      setError(error.message);
      console.error('Error fetching bids:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBids(page + 1, rowsPerPage);
    // eslint-disable-next-line
  }, [page, rowsPerPage]);

  const handleAcceptBid = async (bidId) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/admin/bids/${bidId}/accept`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${sessionStorage.getItem('adminToken')}`
        }
      });
      if (response.ok) {
        fetchBids(page + 1, rowsPerPage);
      } else {
        throw new Error('Failed to accept bid');
      }
    } catch (error) {
      setError(error.message);
      console.error('Error accepting bid:', error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getStatusChipColor = (status) => {
    switch (status.toLowerCase()) {
      case 'pending':
        return 'warning';
      case 'accepted':
        return 'success';
      case 'rejected':
        return 'error';
      default:
        return 'default';
    }
  };

  if (loading && bids.length === 0) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant="h4" sx={{ mb: 4 }}>Bids Management</Typography>
      
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      <TableContainer component={Paper} sx={{ mb: 2 }}>
        <Table sx={{ minWidth: 650 }} aria-label="bids table">
          <TableHead>
            <TableRow>
              <TableCell>Task</TableCell>
              <TableCell>Writer</TableCell>
              <TableCell align="right">Amount</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {bids.map((bid) => (
              <TableRow 
                key={bid.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell>{bid.taskTitle}</TableCell>
                <TableCell>{bid.userFullName}</TableCell>
                <TableCell align="right">Ksh {bid.amount.toLocaleString()}</TableCell>
                <TableCell>
                  <Chip 
                    label={bid.status}
                    color={getStatusChipColor(bid.status)}
                    size="small"
                  />
                </TableCell>
                <TableCell>{new Date(bid.createdAt).toLocaleString()}</TableCell>
                <TableCell>
                  {bid.status === 'pending' && (
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => handleAcceptBid(bid.id)}
                    >
                      Accept
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
            {bids.length === 0 && (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  <Typography variant="body1" sx={{ py: 2 }}>
                    No bids found
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        component="div"
        count={pagination.totalItems}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25, 50]}
      />
    </Box>
  );
};

export default BidsManagement;